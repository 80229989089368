<template>
    <div class="modal-select">
        <!-- <div class="title" v-html="$translate(options.title.toUpperCase())"></div> -->
        <div class="title" v-html="'주제를 선택해주세요'"></div>
        <div class="options">
            <div
                class="option"
                :class="{ 'c-primary f-medium': selectedId === option.id }"
                v-for="option in options.options"
                :key="option.id"
                @click="onSelectOption(option)"
            >
                <div class="option-title" v-html="showOptionName(option)" />
                <div v-if="options.title === 'category'" class="option-content">
                    <span class="option-content-text" v-html="showOptionContent(option)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalSelect',
    props: {
        options: {
            type: Object,
        },
    },
    data: () => ({
        selectedId: null,
    }),
    methods: {
        showOptionName(option) {
            if (this.options.title === 'category') return option.title
            if (this.options.title === 'state') return option.name
            if (this.options.title === 'FRIEND_STATE' || this.options.title === 'FRIEND_AGE') return option.name
            return option.name
        },
        showOptionContent(option) {
            return option.content
        },
        onSelectOption(option) {
            if (option.handler) {
                option.handler()
            } else {
                this.selectedId = option.id
                this.$emit('close', option)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.modal-select {
    padding: 0;
    line-height: normal;
    color: black;
    letter-spacing: -0.2px;

    .title {
        text-align: center;
        padding: 20px 20px 16px;
        border-bottom: 1px solid $grey-02;
        font-weight: 500;
        font-size: 18px;
        @include f-medium;
    }

    .options {
        padding: 20px;
        max-height: 400px;
        overflow-y: scroll;

        .option {
            font-size: 15px;
            font-weight: bold;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        .option-content {
            margin-top: -4px;

            .option-content-text {
                font-size: 12px;
                color: $grey-06;
                font-weight: normal;
            }
        }
    }
}
</style>
